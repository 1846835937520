
/**
 * Get a cookie with a given name
 *
 * @param {string} name The name of the cookie to retrieve from the browser
 * @returns {?string} Value of the cookie with the given name. Null if it can't be found.
 */
export const getCookie = (name: string): string | null => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while(c.charAt(0) === " ") {
      c = c.substring(1, c.length);
    }
    if(c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
};
