import { eventWithTime } from "rrweb"
import { Logger } from './utils/logger'

type EndpointResponseType = {
  url: string,
  fields: { [key: string]: string }
}
class RecordingStorage {
  static recordingsApiUrl = 'https://b9r8u7pkx0.execute-api.eu-west-1.amazonaws.com/v1'
  _endpoint: EndpointResponseType | null = null
  formId: string
  domain: string
  replayId: string

  static async provision({formId, domain, replayId}: {formId: string, domain: string, replayId: string}) {
    const resp = await fetch(`${this.recordingsApiUrl}/domains/${domain}/forms/${formId}/recordings`, {
      method: 'POST',
      body: JSON.stringify({
        replay_id: replayId, // TODO: Use the replayId returned from the API
      }),
      headers: {'Content-Type': 'application/json'},
    });
    const endpoint = await resp.json();
    switch(resp.status) {
      case 200: // OK
        return Promise.resolve(new RecordingStorage({endpoint, formId, domain, replayId}));
      case 404: // Not Found
        return Promise.reject(`Session Replay is not available for the form with ID '${formId}' on domain '${domain}'`)
      default: // Unsupported response status
        return Promise.reject(`Failed to setup storage endpoint`)
    }
  }

  constructor({endpoint, formId, domain, replayId}:
    {endpoint: EndpointResponseType, formId: string, domain: string, replayId: string}) {
    this._endpoint = endpoint;
    this.domain = domain;
    this.replayId = replayId;
    this.formId = formId;
  }

  _prepareS3RequestBody({events, endpoint}: {events: eventWithTime[], endpoint: EndpointResponseType}) {
    const s3Request = new FormData();
    for (const [key, value] of Object.entries(endpoint.fields)) s3Request.append(key, value);
    s3Request.append('file', new Blob([JSON.stringify({
      events,
      replayId: this.replayId,
      formSlug: this.formId,
      domain: this.domain,
    })], { type: 'application/json' }), `${new Date().getTime()}.json`);
    return s3Request;
  }

  saveEvents({events, logger}: {events: eventWithTime[], logger: Logger}) {
    if (this._endpoint) {
      logger.log(`About to save events for replayId: ${this.replayId} to URL: ${this._endpoint.url}`)
      logger.log(events)
      return fetch(this._endpoint.url, {
        method: 'POST',
        body: this._prepareS3RequestBody({events, endpoint: this._endpoint}),
      })
    }
  }

  saveEventsBeacon({events, logger}: {events: eventWithTime[], logger: Logger}) {
    if (this._endpoint) {
      logger.log(`About to save events via sendBeacon for replayId: ${this.replayId} to URL: ${this._endpoint.url}`)
      navigator.sendBeacon(this._endpoint.url, this._prepareS3RequestBody({events, endpoint: this._endpoint}))
    }
  }
}

export default RecordingStorage;
