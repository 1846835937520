import { Record } from './record'
import { getCookie } from './utils/cookie'
import { getDomain } from './utils/domain'
import { Logger } from './utils/logger'

type RecordFormParams = {
  slug: string,
}

export type InitParams = {
  slug: string,
  domain: string,
  debug?: boolean,
}

interface RecordingsInterface {
  [key: string]: Record,
}

interface ZukoReplayInterface {
  /**
	 * Calling this will initalise and store the ZukoReplay recording instance
	 * @example ZukoReplay.recordForm({slug: '123'})
	 * @param {string} slug The "slug" identifier assigned to the form
	 */
  recordForm: ({slug}: RecordFormParams) => Record | undefined,

  /**
   * Stores ZukoReplay recordings against the form slug
   *
   * @example {['my-form']: RecordInstance}
   */
  recordings: RecordingsInterface,

  /**
   * Sets a recording for a form slug in the ZukoReplay.recordings store
   *
   * @example ZukoReplay.setRecording({slug: 'my-form', RecordInstance: RecordInstance});
   *
   * @param {string} slug The "slug" identifier assigned to the form. Uses to store a form's recording
   * @param {Record} RecordInstance The instance of the ZukoReplay recording
   * @param {Logger} logger A logger instance
   */
  setRecording: ({slug, RecordInstance, logger}: {slug: string, RecordInstance: Record, logger: Logger}) => void,
}


const ZukoReplay = {} as ZukoReplayInterface;

ZukoReplay.recordForm = ({slug}: RecordFormParams) => {
  const debug = getCookie("ZukoDebug") === "true" ? true : false
  const logger: Logger = new Logger({debug})

  try {
    if (!slug) throw new Error('a slug is required')
    if (ZukoReplay.recordings[slug]) throw new Error("a RecordInstance is already set for this slug")

    const RecordInstance = new Record({
      slug,
      domain: getDomain(),
      debug,
    })

    ZukoReplay.setRecording({slug, RecordInstance, logger})

    return RecordInstance
  } catch (e) {
    logger.error(`Error initialising recordForm: ${e}`)
  }
}

ZukoReplay.recordings = {}

ZukoReplay.setRecording = ({slug, RecordInstance, logger}) => {
  try {
    if (!slug) throw new Error("a slug is required for ZukoReplay.setRecording");
    if (!RecordInstance) throw new Error("a RecordInstance is required for ZukoReplay.setRecording");

    if (slug && RecordInstance) {
      logger.log(`Setting a recording on ZukoReplay.recordings for slug: ${slug}`);
      ZukoReplay.recordings[slug] = RecordInstance;
    }
  } catch (e) {
    logger.error(`Unable to set recording: ${e}`)
  }
}

export default ZukoReplay
