/**
 * Gets the domain of the site up to and including it's public suffix
 *
 * @example
 * Zuko.getDomain("foo.bar.isimo.google.co.uk")
 * // returns "google.co.uk"
 * @returns {string} The highest domain for the current site
 */
export const getDomain = (): string => {
  let domain = location.hostname;
  const domainParts = domain.split(".");
  let cutoff = domainParts.length - 1;
  const cookieName = "ZukoDomainCheck_" + (new Date()).getTime();
  const isHTTPS = document.location.protocol === 'https:';
  while(cutoff >= 0 && document.cookie.indexOf(cookieName + "=" + cookieName) === -1) {
    domain = domainParts.slice(cutoff).join(".");
    document.cookie = isHTTPS ? cookieName + "=" + cookieName + ";SameSite=None; Secure"+ ";domain=" + domain + ";" :
                                  cookieName + "=" + cookieName + ";domain=" + domain + ";";
    cutoff--;
  }
  const expires = new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toUTCString();
  document.cookie = cookieName + "=;expires=" + expires + ";domain=" + domain + ";";
  return domain;
};