export const pageVisibilityListener = ({
	onHidden,
	onVisible,
}: {
  onHidden: () => void,
  onVisible: () => void,
}) => {
	let hidden: string | undefined = undefined
	let visibilityChangeEventName: string | undefined = undefined

	if (typeof document.hidden !== 'undefined') {
		// Opera 12.10 and Firefox 18 and later support
		hidden = 'hidden'
		visibilityChangeEventName = 'visibilitychange'
		// @ts-expect-error Property 'msHidden' does not exist on type 'Document'.
	} else if (typeof document.msHidden !== 'undefined') {
		hidden = 'msHidden'
		visibilityChangeEventName = 'msvisibilitychange'
		// @ts-expect-error Property 'webkitHidden' does not exist on type 'Document'.
	} else if (typeof document.webkitHidden !== 'undefined') {
		hidden = 'webkitHidden'
		visibilityChangeEventName = 'webkitvisibilitychange'
	}

	if (visibilityChangeEventName === undefined) {
		return () => {}
	}
	if (hidden === undefined) {
		return () => {}
	}

	const hiddenPropertyName = hidden
	const listener = () => {
		// @ts-expect-error No index signature with a parameter of type 'string' was found on type 'Document
		const tabState = document[hiddenPropertyName]
		if (tabState) {
			onHidden()
		} else {
			onVisible()
		}
	}

	document.addEventListener(visibilityChangeEventName, listener)

	const eventNameToRemove = visibilityChangeEventName

	return () => document.removeEventListener(eventNameToRemove, listener)
}
