import { eventWithTime } from "rrweb"
export class Logger {
	debug: boolean | undefined

	constructor(params: {debug?: boolean}) {
		this.debug = params.debug
	}

	log(msg: string | eventWithTime[]) {
		if (this.debug) {
			const prefix = `[${Date.now()}]`
			console.log.apply(console, [prefix, msg])
		}
	}

	error(msg: string) {
		if (this.debug) {
			const prefix = `[${Date.now()}]`
			console.error.apply(console, [prefix, msg])
		}
	}
}
